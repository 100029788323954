import gql from 'graphql-tag'
import {
  basicUserTemplateDetails,
  userImageTemplateDetails,
  userVideoTemplateDetails
} from './fragments/templateFragment'

export const USER_TEMPLATES = gql`
  query userTemplates(
    $first: Int
    $after: String
    $search: String
    $exclude: ID
    $type: String
    $orderBy: UserTemplateOrderByInput
    $currentStatusOptions: [TemplateRenderStatus!]
  ) {
    userTemplates(
      input: {
        first: $first
        after: $after
        search: $search
        exclude: $exclude
        type: $type
        orderBy: $orderBy
        currentStatusOptions: $currentStatusOptions
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
      edges {
        cursor
        node {
          id
          name
          renderUrl
          type {
            typeName
            displayName
            slug
          }
          thumbnailUrl
          currentStatus {
            id
            status
            message
            createdAt
          }
          template {
            id
            name
            size {
              id
              width
              height
            }
            ... on ImageTemplate {
              id
            }
            ... on VideoTemplate {
              id
            }
          }
          ... on UserImageTemplate {
            id
            size {
              id
              width
              height
            }
          }
          ... on UserVideoTemplate {
            id
          }
        }
      }
    }
  }
`

export const RENDER_USER_VIDEO_TEMPLATE = gql`
  mutation renderUserVideoTemplate($id: ID!) {
    renderUserVideoTemplate(id: $id)
  }
`

export const GET_USER_TEMPLATE = gql`
  query userTemplate($id: ID!) {
    userTemplate(id: $id) {
      ${basicUserTemplateDetails}
    }
  }
`

export const GET_USER_IMAGE_TEMPLATE = gql`
  query userImageTemplate($id: ID!) {
    userImageTemplate(id: $id) {
      ${userImageTemplateDetails}
    }
  }
`

export const GET_USER_VIDEO_TEMPLATE = gql`
  query userVideoTemplate($id: ID!) {
    userVideoTemplate(id: $id) {
      ${userVideoTemplateDetails}
    }
  }
`

export const UPDATE_USER_IMAGE_TEMPLATE = gql`
  mutation UserImageTemplateUpdateInput(
    $id: ID!
    $name: String
    $thumbnailUrl: String
    $renderUrl: String
    $scenes: UserImageTemplateSceneUpdateManyInput
  ) {
    updateUserImageTemplate(
      input: {
        id: $id
        name: $name
        thumbnailUrl: $thumbnailUrl
        renderUrl: $renderUrl
        scenes: $scenes
      }
    ) {
      ${userImageTemplateDetails}
    }
  }
`

export const UPDATE_USER_VIDEO_TEMPLATE = gql`
  mutation updateUserVideoTemplate(
    $id: ID!
    $name: String
    $thumbnailUrl: String
    $renderUrl: String
    $audio: AudioFileUpdateManyInput
    $scenes: [UserVideoTemplateSceneUpdateInput!]
  ) {
    updateUserVideoTemplate(
      input: {
        id: $id
        name: $name
        thumbnailUrl: $thumbnailUrl
        renderUrl: $renderUrl
        audio: $audio
        scenes: $scenes
      }
    ) {
      ${userVideoTemplateDetails}
    }
  }
`

export const CREATE_USER_VIDEO_TEMPLATE = gql`
  mutation createUserVideoTemplate($id: ID!) {
    createUserVideoTemplate(id: $id) {
      ${userVideoTemplateDetails}
    }
  }
`

export const CREATE_USER_IMAGE_TEMPLATE = gql`
  mutation createUserImageTemplate($id: ID!) {
    createUserImageTemplate(id: $id) {
      ${userImageTemplateDetails}
    }
  }
`

export const UPLOAD_USER_TEMPLATE_GENERATED_IMAGES = gql`
  mutation uploadUserTemplateGeneratedImages($templateId: ID!) {
    uploadUserTemplateGeneratedImages(input: { templateId: $templateId }) {
      url
      fields {
        key
        value
      }
    }
  }
`

export const UPDATE_USER_TEMPLATE_SCENE_IMAGES = gql`
  mutation updateUserTemplateSceneImages(
    $id: ID!
    $templateId: ID!
    $backgroundUrl: String!
    $thumbnailUrl: String!
  ) {
    updateUserTemplateSceneImages(
      id: $id
      templateId: $templateId
      backgroundUrl: $backgroundUrl
      thumbnailUrl: $thumbnailUrl
    ) @client
  }
`

export const DELETE_USER_TEMPLATE = gql`
  mutation deleteUserTemplate($ids: [ID!]!) {
    deleteUserTemplate(ids: $ids)
  }
`

export const UPDATE_USER_TEMPLATE_RENDER_STATUS = gql`
  mutation updateUserTemplateRenderStatus(
    $userTemplateId: ID!
    $status: TemplateRenderStatus!
    $payload: String!
  ) {
    updateUserTemplateRenderStatus(
      input: {
        userTemplateId: $userTemplateId
        status: $status
        payload: $payload
      }
    )
  }
`

export const USER_TEMPLATE_RENDER_STATUS_SUBSCRIPTION = gql`
  subscription userTemplateRenderStatusUpdated($userId: ID!) {
    userTemplateRenderStatusUpdated(userId: $userId) {
      mutation
      node {
        status
        userTemplate {
          id
          name
          renderUrl
          type {
            typeName
            displayName
            slug
          }
        }
      }
    }
  }
`

export const CONVERT_IMAGE_TEMPLATE_TO_VIDEO = gql`
  mutation generateVideoFromUserImageTemplate($id: ID!) {
    generateVideoFromUserImageTemplate(id: $id) {
      url
    }
  }
`

export const GENERATE_PDF_FROM_USER_TEMPLATE = gql`
  mutation generatePdfFromUserTemplate($id: ID!) {
    generatePdfFromUserTemplate(id: $id) {
      url
    }
  }
`
