const sceneTemplateImageAsset = `
	... on TemplateSceneImageAsset {

		imageUrl
		size {
			id
			width
			height
		}
		variants {
			id
			color {
				id
				name
				hex
			}
      baseImageUrl

		}
	}
`
const sceneTemplateVideoAsset = `
  ... on TemplateSceneVideoAsset {
    videoUrl
    size {
      id
      width
      height
    }
    duration
    staticFrame
  }
`
const sceneTemplateTextAsset = `
  ... on TemplateSceneTextAsset {
    textAlign
    verticalAlign
    textBoxType
    content
    font {
      id
      family {
       id
          name
          provider {
            name
          }

      }
      variant
      postScriptName
      fontUrl
    }
    fontSize
    fontStyle
    textDecoration
    fontColor
    stroke {
      color
      width
    }
  }
`
const userTemplateImageAsset = `
	... on UserTemplateSceneImageAsset {
		selectedVariant {
			id
			baseImageUrl
			color {
				id
				name
				hex
			}
		}
		size {
			width
			height
		}
		file {
			id
			url
			sourceType
		}
		crop {
			offset {
				id
				x
				y
			}
			size {
				id
				width
				height
			}
      scale
		}

	}
`
const userTemplateVideoAsset = `
	... on UserTemplateSceneVideoAsset {
		duration

		staticFrame
		size {
			width
			height
		}
		crop {
			offset {
				id
				x
				y
			}
			size {
				id
				width
				height
			}
      scale
		}

		trim {
			id
			min
			max
		}
		file {
			id
			url
			sourceType
		}
	}
`
const userTemplateTextAsset = `
  ... on UserTemplateSceneTextAsset {
      content
      fontSize
      fontStyle
      textDecoration
      fontColor
      font{
        id
        family {
          id
          name
          provider {
            name
          }

        }
        variant
        postScriptName
        fontUrl
       }
      textAlign
      verticalAlign
      textBoxType
      stroke {
        color
        width
      }
  }
`

const userTemplateVectorAsset = `
  ... on UserTemplateSceneVectorAsset {
      vectorUrl
      colors
      stroke {
        color
        width
      }
  }
`

export const basicUserTemplateDetails = `
  id
  name
  renderUrl
  thumbnailUrl
  user {
    id
    email
    username
    firstName
    lastName
  }
  type {
    typeName
    displayName
    slug
  }
  currentStatus {
    id
    status
    message
    createdAt
  }
`

export const imageTemplateTypeDetails = `
  id
  name
  hasAccess
  previewUrl
  size{
    width
    height
  }
  platforms{
    id
    name
  }
  scenes {
    id
    sceneOrder
    thumbnailUrl
    backgroundUrl
    backgroundColor
    assets {
      id
      name
      opacity
      rotation
      static
      type {
        typeName
      }
      position {
        x
        y
      }
      scale {
        x
        y
      }
      anchor {
        x
        y
      }
      projection {
        enable
        type { typeName }
        coordinates{
          x
          y
        }
        config
      }
    ${sceneTemplateVideoAsset}
    ${sceneTemplateTextAsset}
    ${sceneTemplateImageAsset}

    }
  }
`
export const videoTemplateTypeDetails = `
  name
  id
  audio {
    id
    url
    startTime
    duration
    type
    trim {
      min
      max
    }
  }
  previewUrl
  duration
  scenes {
    sceneOrder
    duration
    start
    end
    thumbnailUrl
    assets {
      id
      name
      opacity
      rotation
      static
      type { typeName }
      position {
        x
        y
      }
      scale {
        x
        y
      }
      anchor {
        x
        y
      }
      projection {
        enable
        type { typeName }
        coordinates{
          x
          y
        }
        config
      }
      ... on TemplateSceneTextAsset {
        content
        fontSize
        fontStyle
        textDecoration
        fontColor
        font {
          id
          family {
           id
              name
              provider {
                name
              }
          }
          variant
          postScriptName
          fontUrl
        }
        textAlign
        verticalAlign
        stroke {
          color
          width
        }
      }

      ... on TemplateSceneImageAsset {
        imageUrl
        size {
          width
          width
        }
        skew {
          x
          y
        }
      }

      ... on TemplateSceneVideoAsset {
        videoUrl
        size {
          width
          width
        }
        skew {
          x
          y
        }
        duration
        staticFrame
      }
    }
  }
`
export const userTemplateAssetsDetails = `
  assets{
    id
    historyId
    type {
      typeName
    }
    assetOrder
    position {
      x
      y
    }
    scale {
      x
      y
    }
    linkId
    linkedTo
    opacity
    disable
    rotation
    static
    ${userTemplateTextAsset}
    ${userTemplateImageAsset}
    ${userTemplateVideoAsset}
    ${userTemplateVectorAsset}
    templateSceneAsset {
      id
      position {
        x
        y
      }
      projection {
        enable
        type { typeName }
        config
        coordinates{
          x
          y
        }
      }
      anchor {
        x
        y
      }
      name
      type {
        typeName
      }
      ${sceneTemplateVideoAsset}
      ${sceneTemplateTextAsset}
      ${sceneTemplateImageAsset}
    }
  }
`
export const sceneTemplateAssetsDetails = `
  assets {
    id
    assetOrder
    anchor {
      x
      y
    }
    position {
      x
      y
    }
    scale {
      x
      y
    }
    linkId
    linkedTo
    name
    opacity
    rotation
    static
    ${sceneTemplateVideoAsset}
    ${sceneTemplateTextAsset}
    ${sceneTemplateImageAsset}
    type {
      typeName
    }
    projection {
      enable
      type { typeName }
      coordinates {
        x
        y
      }
      config
    }
  }
`
export const userImageTemplateDetails = `
  ${basicUserTemplateDetails}
  size
  {
    id
    width
    height
  }
  template {
    id
		name
		type {
			typeName
      displayName
      slug
		}
		previewUrl
		size {
			id
			width
			height
		}
  }
  scenes {
    id
    thumbnailUrl
    backgroundUrl
    backgroundColor
    sceneOrder
    disable
    historyId
    ${userTemplateAssetsDetails}
    imageTemplateScene {
      id
      backgroundUrl
      backgroundColor
      thumbnailUrl
      sceneOrder
      ${sceneTemplateAssetsDetails}
    }
  }
`
export const userVideoTemplateDetails = `
  ${basicUserTemplateDetails}
  template {
		id
		name
		type {
			typeName
      displayName
      slug
		}
		previewUrl
		size {
			id
			width
			height
		}
		... on VideoTemplate {
			duration
		}
	}
  audio {
		id
		url
		trim {
			id
			min
			max
		}
		duration
		startTime
		type
	}
  scenes {
    id
    backgroundUrl
    thumbnailUrl
    sceneOrder
    ${userTemplateAssetsDetails}
    videoTemplateScene {
      id
      backgroundUrl
      backgroundColor
      thumbnailUrl
      sceneOrder
      duration
      start
      end
      ${sceneTemplateAssetsDetails}
    }
  }
`
export const templateTypeDetails = `
	__typename
	id
	name
	description
	previewUrl
	thumbnailUrl
	createdAt
  newBadge
  hasAccess
  assetCount {
    textAssets
    imageAssets
    videoAssets
  }
	orientation {
		name
	}
	feel {
		name
	}
	energy {
		name
	}
	tags {
		name
	}
	type {
		typeName
		displayName
    slug
	}
	platforms {
		name
	}
	categories {
		name
	}
  size {
    width
    height
  }
  subscriptions {
    name
    type {
      typeName
      displayName
    }
  }
  ... on VideoTemplate{
    duration
  }
  favorited @include(if: $authenticated)
  userLike @include(if: $authenticated) {
    status
  }
`
export default `
  fragment templateDetails on TemplateConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    aggregate {
      count
    }
    edges {
      node {
        ${templateTypeDetails}
      }
    }
  }
`
